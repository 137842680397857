import React, { useEffect, useState } from "react";
import FlexibleContent from "../components/reusable/FlexibleContent";
import { graphql } from "gatsby";
import { Link } from "gatsby";
import { Styling } from "../styles/templates/student-success-post.styled";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import parse from "html-react-parser";
import Seo from "../components/global/seo";
import { RellaxWrapper } from "react-rellax-wrapper";

gsap.registerPlugin(ScrollTrigger);

export default function StudentSuccessPost({ data }) {
  const [image, setImage] = useState({
    heroType: "image",
    heroSrc: data?.masteringInfo?.masteringFields?.heroImageMastering?.localFile?.publicURL,
  });

  useEffect(() => {
    let hero = null;
    let pinSpacer = null;

    const body = document.querySelector("body");
    hero = document.createElement("div");
    const imageEl = document.createElement("img");
    hero.classList.add("testimonials-hero");
    hero.classList.add("offscreen-translator");
    imageEl.setAttribute("src", image.heroSrc);
    body.appendChild(hero);
    hero.appendChild(imageEl);
    ScrollTrigger.create({
      trigger: ".scroll-content",
      pin: ".testimonials-hero",
      start: "top top",
      end: "bottom bottom",
      pinSpacing: false,
    });

    return () => {
      hero.remove();
      if (typeof window !== "undefined") {
        const spacers = document.querySelectorAll(".pin-spacer");
        spacers.forEach((spacer) => spacer.remove());
      }
    };
  }, [image]);

  return (
    <Styling>
      <Seo title={data.masteringInfo.seo.title} description={data.masteringInfo.seo.metaDesc} />
      <div className="scroll-content">
        <RellaxWrapper speed={-9}>
          <GatsbyImage
            className={`mobileImg`}
            image={getImage(data?.masteringInfo?.masteringFields?.heroImageMastering?.localFile)}
            alt={"Toolroom Mastering Service"}
          />
        </RellaxWrapper>
        <div className="testiIntro">
          <h2 className="main-title">{data.masteringInfo.title}</h2>
        </div>
        <FlexibleContent flexible={data.flexible} />
        <button
          className="snipcart-add-item capsule_button black"
          data-item-id="mastering"
          data-item-price="0"
          data-item-url="/mastering"
          // data-item-min-quantity="1"
          // data-item-max-quantity="1"
          data-item-description="High-quality mastering service"
          data-item-name="Toolroom Mastering"
          data-item-categories={"mastering"}
          data-item-custom1-name="Choose mastering product"
          data-item-custom1-options="1 Track Master[+50.00] | 2 Track Master[+100.00] | 3 Track Master[+150.00] | 4 Track Master[+200.00] | 5 Track Master[+250.00] | 6 Track Master[+300.00] | 7 Track Master[+350.00] | 8 Track Master[+400.00] | 9 Track Master[+450.00] | 10 Track Master[+500.00] | Alternative Mix Master[+15.00] | Additional Revision[+10.00]"
          // data-item-custom1-required="true"
          // data-item-custom2-name="Link to your track"
          // data-item-custom2-required="true"
          // data-item-custom3-name="Reference Master"
          // data-item-custom3-required="true"
          // data-item-custom4-name="Notes"
          // data-item-custom4-type="textarea"
          data-item-shippable={false}>
          Add to cart
        </button>
      </div>
    </Styling>
  );
}

export const query = graphql`
  query {
    masteringInfo: wpPage(title: { eq: "Mastering" }) {
      title
      seo {
        metaDesc
        title
      }
      masteringFields {
        heroImageMastering {
          localFile {
            publicURL
            childImageSharp {
              gatsbyImageData(
                width: 3000
                placeholder: BLURRED
                formats: [AUTO, WEBP]
                layout: FULL_WIDTH
              )
            }
          }
        }
      }
    }
    flexible: wpPage(title: { eq: "Mastering" }) {
      flexibleContent {
        postBuilder {
          ... on WpPage_Flexiblecontent_PostBuilder_TextBlock {
            fieldGroupName
            text
          }
          ... on WpPage_Flexiblecontent_PostBuilder_Quote {
            fieldGroupName
            quoteText
          }
          ... on WpPage_Flexiblecontent_PostBuilder_AudioPlayer {
            fieldGroupName
            trackTile
            audioFile {
              localFile {
                publicURL
              }
            }
          }
          ... on WpPage_Flexiblecontent_PostBuilder_ImageBlock {
            fieldGroupName
            image {
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    width: 1500
                    placeholder: BLURRED
                    formats: [AUTO, WEBP]
                    layout: FULL_WIDTH
                  )
                }
              }
            }
          }
          ... on WpPage_Flexiblecontent_PostBuilder_VideoBlock {
            fieldGroupName
            video
          }
        }
      }
    }
  }
`;
